import React, { useState, useRef } from "react"
import { Scrollbar, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { Element, scroller } from "react-scroll"

// import Popup from "./popup"
import Intro from "./intro"
import GumPackLockup from "./gum-pack-lockup"
import Chew from "./extra-gum-carousel/chew"
import Post from "./extra-gum-carousel/post"
import Win from "./extra-gum-carousel/win"
import UploadReceipt from "./upload-receipt"
import Rules from "./rules"

// import "swiper/css/bundle"
// import "swiper/css/navigation"
// import "swiper/css/pagination"
// import "swiper/css/scrollbar"
import "swiper/css/bundle"

const ExtraGumCarousel = () => {
  const [showForm, setShowForm] = useState(false)
  const [showRules, setShowRules] = useState(false)
  const swiperRef = useRef(null)

  const goToCarousel = () => {
    scroller.scrollTo("carouselElement", {
      duration: 500,
      //delay: 100,
      smooth: true,
      //containerId: "ContainerElementID",
      //offset: 50, // Scrolls to element + 50 pixels down the page
    })
    setTimeout(() => {
      swiperRef.current.swiper.slideTo(0)
    }, 1000)
  }
  const goToSlideTwo = () => {
    // uploadReceiptBtnRef.current.click()
    scroller.scrollTo("carouselElement", {
      duration: 500,
      smooth: true,
    })
    setTimeout(() => {
      swiperRef.current.swiper.slideTo(1)
      document.getElementById("upload-btn").click()
      scroller.scrollTo("uploadReceipt", {
        duration: 500,
        smooth: true,
        offset: -30,
      })
    }, 1000)
  }
  return (
    <>
      <Intro goToCarousel={goToCarousel} goToSlideTwo={goToSlideTwo} />
      <GumPackLockup />
      <Element name="carouselElement"></Element>
      <section className="pb-3">
        <div className="container">
          {/* <button onClick={clickhandler}>Slide to the next slide</button> */}
          <Swiper
            ref={swiperRef}
            // install Swiper modules
            modules={[Scrollbar, A11y]}
            // spaceBetween={50}
            slidesPerView={1}
            autoHeight={true}
            // navigation
            // pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            // onSwiper={swiper => console.log(swiper)}
            onSlideChange={swiper => {
              if (swiper.realIndex === 1) {
                setShowForm(true)
                setShowRules(false)
              } else if (swiper.realIndex === 2) {
                setShowRules(true)
                setShowForm(false)
              } else {
                setShowForm(false)
                setShowRules(false)
              }
              // swiper.realIndex === 1 ? setShowForm(true) : setShowForm(false)
              // swiper.realIndex === 2 ? setShowRules(true) : setShowRules(false)
            }}
            id="swiper"
            className="text-center text-white pt-4 pt-md-5 pb-4 pb-md-5"
          >
            <SwiperSlide>
              <Chew />
            </SwiperSlide>
            <SwiperSlide>
              <Post />
            </SwiperSlide>
            <SwiperSlide>
              <Win />
            </SwiperSlide>
          </Swiper>

          <p className="text-center text-white mt-4">Scroll to learn more</p>
        </div>
      </section>

      <Element name="uploadReceipt"></Element>
      {showForm && (
        <>
          <UploadReceipt />
        </>
      )}

      {showRules && (
        <>
          <Rules />
        </>
      )}
    </>
  )
}

export default ExtraGumCarousel
