import React, { useEffect } from "react"

import Layout from "../../components/extragum components/components/layout"

// import Form from "../../components/extragum components"
import ScrollWheelAnimation from "../../components/extragum components/components/home/scroll-wheel-animation"

import ExtraGumCarousel from "../../components/extragum components/components/home/extra-gum-carousel"


const IndexPage = () => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "virtualPageview",
      page: "/landingpage",
    })
  }, [])
  return (
    <Layout>
      
      <ScrollWheelAnimation />

      <ExtraGumCarousel />
      {/* <FormRecaptcha /> */}
    </Layout>
  )
}

export default IndexPage
