import React, { useState, useCallback, useRef } from "react"
// import SelectDatepicker from "react-select-datepicker"
import PhoneInput from "react-phone-input-2"
import Tesseract from "tesseract.js"
import { useForm } from "react-hook-form"

// import "react-phone-input-2/lib/style.css"

const Form = ({ setActiveForm, setShowPopup }) => {
  const [birthValue, setBirthValue] = useState(null)
  const [birthValueError, setBirthValueError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [progressBar, setProgressBar] = useState(0)
  const [text, setText] = useState("no")
  // const [image, setImage] = useState("")
  const [imageUrl, setImageUrl] = useState("")
  const [phoneNumber, setPhoneNumber] = useState(null)
  const uploadFileRef = useRef(null)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  // const showBirthErrorMessage = () => {
  //   if (birthValue === null) {
  //     setBirthValueError(true)
  //     if (document.querySelector("#birthError")) {
  //       document.querySelector("#month").focus()
  //     } else {
  //       return false
  //     }
  //   }
  // }

  const extractTextFromImage = e => {
    // setImage(URL.createObjectURL(e.target.files[0]))
    setImageUrl(e.target.value)
    setIsLoading(true)
    setProgressBar(0)
    Tesseract.recognize(URL.createObjectURL(e.target.files[0]), "eng", {
      logger: m => {
        console.log(m)
        if (m.status === "recognizing text") {
          setProgressBar(parseInt(m.progress * 100))
        }
      },
    }).then(({ data: { text } }) => {
      //   console.log(text)
      const extractedText = text.toLowerCase()
      console.log(extractedText)
      if (
        extractedText.indexOf("extra") !== -1 ||
        extractedText.indexOf("gum") !== -1 ||
        extractedText.indexOf("extragum") !== -1 ||
        extractedText.indexOf("orbit") !== -1 ||
        extractedText.indexOf("orbitgum") !== -1
      ) {
        setText(text)
        // alert("Available")
      } else {
        setText("")
      }

      setIsLoading(false)
    })
  }

  const onSubmit = data => {
    // let birthday
    // if (birthValue !== null) {
    //   const months = [
    //     "January",
    //     "February",
    //     "March",
    //     "April",
    //     "May",
    //     "June",
    //     "July",
    //     "August",
    //     "September",
    //     "October",
    //     "November",
    //     "December",
    //   ]
    //   const month = months[birthValue.getMonth()]
    //   const day = birthValue.getDate()
    //   const year = birthValue.getFullYear()
    //   birthday = `${month}-${day}-${year}`
    //   console.log(birthday)
    //   data.birthday = birthday
      data.text = text
      data.phoneNumber = phoneNumber
      console.log(data)
      reset()
      setImageUrl("")
      // setBirthValue(null)
      setPhoneNumber(null)

      // window.dataLayer = window.dataLayer || []
      // window.dataLayer.push({
      //   event: "emailsubmit",
      //   email: data.email,
      //   firstName: data.firstName,
      //   lastName: data.lastName,
      //   dob: data.birthday,
      //   phone: data.phoneNumber,
      //   status: "success",
      //   optin: data.marsPrivacyPolicy,
      //   marketingOptin: data.marketingCommunications,
      //   receipt: data.text,
      //   payment: data.payMethods,
      // })
      if (imageUrl === "") {
        setShowPopup(true)
      }
      setActiveForm(false)
    }
  // }
  // const onDateChange = useCallback(date => {
  //   setBirthValue(date)
  //   setBirthValueError(false)
  // }, [])

  return (
    <>
      {/* <GoogleReCaptchaProvider reCaptchaKey="6LdhYX4eAAAAAOerO3Cga3Wa8U9-2z1e7Tl9bti5"> */}
        <form
          className="upload-receipt-form text-start"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="mb-4">
            <label htmlFor="fname" className="form-label">
              First Name*
            </label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              placeholder="FIRST NAME"
              name="First Name"
              {...register("firstName", { required: true })}
            />
            {errors.firstName && (
              <span className="input-error">First Name is required</span>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="lname" className="form-label">
              Last Name*
            </label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              placeholder="LAST NAME"
              name="Last Name"
              {...register("lastName", { required: true })}
            />
            {errors.lastName && (
              <span className="input-error">Last Name is required</span>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="form-label">
              Email*
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="NAME@EMAIL.COM"
              name="email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Please enter a valid email",
                },
              })}
            />
            {errors.email && (
              <span className="input-error">{errors.email.message}</span>
            )}
          </div>
          {/* <div className="mb-4">
            <label className="form-label">Birthdate*</label>
            <SelectDatepicker
              id="birthdate"
              value={birthValue}
              onDateChange={onDateChange}
              minDate={new Date(1900, 0, 1)}
              maxDate={new Date()}
              showLabels={false}
              labels={{ year: "YYYY", month: "MM", day: "DD" }}
            />
            {birthValueError && (
              <span className="input-error" id="birthError">
                Birthday is required
              </span>
            )}
          </div> */}

          <div className="mb-4">
            <label htmlFor="phone" className="form-label">
              Phone Number*
            </label>
            <PhoneInput
              country={"us"}
              onlyCountries={["us"]}
              placeholder="(XXX) XXX-XXXX"
              value={phoneNumber}
              onChange={phone => {
                setPhoneNumber(phone)
                //console.log(phoneNumber)
              }}
              disableDropdown={true}
              disableCountryCode={true}
              inputClass="form-control"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="real-upload-file" className="form-label">
              Upload Receipt File
            </label>
            <input
              type="file"
              id="real-upload-file"
              hidden="hidden"
              onChange={e => {
                extractTextFromImage(e)
              }}
              ref={uploadFileRef}
              accept="image/png, image/jpeg"
            />
            <button
              type="button"
              className="text-uppercase"
              id="custom-upload-file"
              onClick={() => uploadFileRef.current.click()}
            >
              Upload File
            </button>
            <span id="custom-upload-text" className="d-inline-block ms-2">
              {imageUrl.split("\\").pop()}
            </span>

            {isLoading && (
              <>
                <div className="progress mt-3">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={`${progressBar}`}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: `${progressBar}%` }}
                  ></div>
                </div>
              </>
            )}
          </div>

          <div className="mb-4">
            <div>
              <label className="form-label">Preferred Method of Payment*</label>
            </div>

            <div className="form-check-inline">
              <label className="form-check-custom">
                <input
                  className="form-check-input"
                  type="radio"
                  name="payMethods"
                  value="paypal"
                  {...register("payMethods", { required: true })}
                />
                <span className="checkmark"></span>
                PayPal
              </label>
            </div>
            <div className="form-check-inline">
              <label className="form-check-custom">
                <input
                  className="form-check-input"
                  type="radio"
                  name="payMethods"
                  value="venmo"
                  {...register("payMethods", { required: true })}
                />
                <span className="checkmark"></span>
                Venmo
              </label>
            </div>
            <div>
              {errors.payMethods && (
                <span className="input-error">Pay Method is required</span>
              )}
            </div>
          </div>

          <div className="mb-4">
            <label className="form-check-custom">
              <input
                className="form-check-input"
                id="marsPrivacyPolicy"
                type="checkbox"
                value=""
                {...register("marsPrivacyPolicy", { required: true })}
              />
              <span className="checkmark"></span>I agree to the{" "}
              <a href="https://www.mars.com/privacy" target="_blank">
                Mars Privacy Policy{" "}
              </a>
              and to be bound by the{" "}
              <a href="#" target="_blank">
                Official Rules
              </a>{" "}
              and the decisions of the Sponsor.
            </label>
            {errors.marsPrivacyPolicy && (
              <span className="input-error">Consent is required</span>
            )}
          </div>

          <div className="mb-4">
            <label className="form-check-custom">
              <input
                className="form-check-input"
                id="marketingCommunications"
                type="checkbox"
                value=""
                {...register("marketingCommunications")}
              />
              <span className="checkmark"></span>I would like to receive future
              marketing communications from EXTRA® and other Mars Wrigley brands
              via the email provided. You can withdraw this consent at any time.
            </label>
          </div>

          <div className="text-center mt-4 mt-md-5">
            <button
              type="submit"
              className="btn btn-primary rounded-pill mt-md-4"
              // onClick={() => showBirthErrorMessage()}
            >
              Submit
            </button>
          </div>
        </form>
      {/* </GoogleReCaptchaProvider> */}
    </>
  )
}

export default Form
