/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"

import Helmet from "react-helmet"

// Fonts
import FontUrl1 from "../fonts/GoldplayBlack/Goldplay-Black.woff2"
import FontUrl2 from "../fonts/GothamBook/Gotham-Book.woff2"
import FontUrl3 from "../fonts/GothamMedium/Gotham-Medium.woff2"

// import Header from "./header"

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <link
          rel="preload"
          as="font"
          href={FontUrl1}
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          href={FontUrl2}
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          href={FontUrl3}
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link rel="stylesheet" href="../extragum/css/app.css" />
        <link rel="stylesheet" href="../extragum/css/responsive.css" />
      </Helmet>
      <main>{children}</main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
