import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Popup = ({ setShowPopup }) => {
  const closePopup = () => {
    setShowPopup(false)
  }
  return (
    <>
      <section className="popup-hldr d-flex justify-content-center align-items-center">
        <div className="popup text-white d-flex flex-column justify-content-center align-items-center position-relative">
          <figure
            className="popup-close mb-0 position-absolute"
            onClick={() => closePopup()}
          >
            {/* <img
              className="img-fluid"
              src="images/popup-close.png"
              alt="popup close"
            /> */}
            <StaticImage
              src="../../images/popup-close.png"
              alt="popup close"
              quality={100}
            />
          </figure>
          <h3 className="text-center">
            OOPS! LOOKS LIKE YOU DIDN'T UPLOAD A RECEIPT
          </h3>
          <div className="d-md-flex justify-content-center align-items-center">
            <figure className="icon-selfie">
              {/* <img
                className="img-fluid"
                src="images/icons_selfie-icon-popup.png"
                alt=""
              /> */}
              <StaticImage
                src="../../images/icons_selfie-icon-popup.png"
                alt="Icon Selfie"
                quality={100}
              />
            </figure>

            <p className="text-lg mb-0">
              If you don't have a receipt, you can{" "}
              <span className="light">
                enter by posting on your public social media account{" "}
              </span>
              with #ChewItBeforeYouDoIt #Sweeps
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

export default Popup
