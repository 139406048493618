import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const GumPackLockup = () => {
  return (
    <>
      <section className="py-4 py-md-5">
        <div className="container">
          <figure className="mb-0">
            {/* <img src="images/gum-pack-lockup.png" alt="" className="img-fluid" /> */}
            <StaticImage
              src="../../images/gum-pack-lockup.png"
              alt="Gum Pack Lockup"
              quality={100}
            />
          </figure>
        </div>
      </section>
    </>
  )
}

export default GumPackLockup
