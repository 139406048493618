import React, { useState } from "react";

import { Element, scroller } from "react-scroll";

const Rules = () => {
  const [rulesClose, setRulesClose] = useState(false);
  const goToRulesHandler = () => {
    scroller.scrollTo("rulesElement", {
      duration: 500,
      //delay: 100,
      smooth: true,
      //containerId: "ContainerElementID",
      //offset: 50, // Scrolls to element + 50 pixels down the page
    });
    if (rulesClose) setRulesClose(false);
    else setRulesClose(true);
  };
  return (
    <>
      <section className="pb-5">
        <div className="container">
          <div className="text-center">
            <div className="accordion" id="accordionRules">
              <button
                type="button"
                className="btn btn-primary rounded-pill mx-2"
                data-bs-toggle="collapse"
                data-bs-target="#rules"
                aria-expanded="false"
                aria-controls="rules"
                onClick={goToRulesHandler}
              >
                {rulesClose ? "Close Rules" : "See Rules"}
              </button>
              <Element name="rulesElement"></Element>
              <div
                id="rules"
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionRules"
              >
                <div className="py-5">
                  <div className="rules text-start">
                    <p className="fw-bold">OFFICIAL RULES</p>
                    <p className="fw-bold">
                      NO PURCHASE NECESSARY TO ENTER. A PURCHASE WILL NOT
                      INCREASE YOUR CHANCES OF WINNING. A VALID EMAIL ADDRESS IS
                      REQUIRED.
                    </p>
                    <p>
                      <span className="fw-bold">1. ELIGIBILITY:</span> The
                      Extra® Gum Moments Sweepstakes (the “Sweepstakes”) is
                      offered only to legal residents of the 50 United States
                      and the District of Columbia, who are 16 years of age or
                      older at the time of entry. Eligible entrants who are less
                      than the age of majority in their state of primary
                      residence (a 'mino') must have a parent/legal guardian's
                      permission to participate. Void where prohibited or
                      restricted by law. Employees, officers and directors, of
                      Mars Wrigley Confectionery US, LLC (“Sponsor”), MARS
                      Advertising, Inc. d/b/a the Mars Agency, TPG Rewards
                      (“Administrator”), Inc., Promotion Fulfillment Center,
                      their respective owners, parents, subsidiaries and
                      affiliates, advertising and promotion agencies, and any
                      companies involved in the implementation and execution of
                      the Sweepstakes (collectively, the "Sweepstakes Entities")
                      and their immediate family members (spouses, siblings,
                      parents, and children, including foster and
                      step-relations, and their respective spouses) and
                      household members (whether or not related) are not
                      eligible. By participating, each entrant fully and
                      unconditionally agrees to and accepts these Official Rules
                      and the decisions of the Sponsor (and its authorized third
                      parties), which are final and binding on all matters
                      relating to the Sweepstakes.
                    </p>

                    <p>
                      <span className="fw-bold">2. SWEEPSTAKES PERIOD:</span>
                      Sweepstakes starts on or about 12:00:00 a.m. Eastern Time
                      (ET) on February 28, 2022 and ends at 11:59:59 p.m. ET on
                      April 30, 2022 (“Sweepstakes Period”). The Sweepstakes
                      Period is comprised of four (4) separate entry periods
                      (each, an “Entry Period”). At the conclusion of each Entry
                      Period, Grand Prize Winners will be selected in a random
                      drawing from among all eligible entries received from all
                      Entry Methods combined during the corresponding Entry
                      Period (each and collectively, the “Grand Prize Winners”).
                      The number of Grand Prize winners selected from each Entry
                      Period and the details of the Entry Periods are detailed
                      in Rules 4 below.
                    </p>
                    <p>
                      <span className="fw-bold">3. HOW TO ENTER:</span> There
                      are four (4) ways to enter the Sweepstakes.
                    </p>
                    <p>
                      <span className="fw-bold">a. Qualified Purchase.</span>{" "}
                      During the Sweepstakes Period an eligible entrant
                      (“Entrant”) can purchase any package of Extra® or ORBIT®
                      gum (a “Qualified Purchase”), and upload a photo of the
                      receipt for that Qualified Purchase at{" "}
                      <a
                        href="https://www.extragum.com/sweeps"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://www.extragum.com/sweeps
                        <span className="visually-hidden">
                          opens in a new window
                        </span>
                      </a>{" "}
                      (the “Website”) during an Entry Period. Entrants on the
                      Website must follow the online directions to: (i) submit
                      their birthdate; (ii) submit his/her first and last name,
                      street address (no PO Boxes allowed), state, city, ZIP
                      code, email address, daytime telephone number; (iii) agree
                      to be bound by these Official Rules and the decisions of
                      the Sponsor and its authorized third parties; and (iv)
                      upload a photo of the receipt showing a Qualified Purchase
                      to the Website. The photo must clearly show all of the
                      following (i) the name of the U.S. retailer where the
                      Qualified Purchase was made; (ii) date of Qualified
                      Purchase (must be made between February 28, 2022 and April
                      30, 2022; (iii) time of Qualified Purchase; (iv) receipt
                      total; and (iv)reflect that there was a completed sale of
                      one (1) or more Qualified Purchases. After successful
                      completion of the above steps, Entrant will receive one
                      (1) entry into the corresponding Entry Period that the
                      receipt is submitted (“Entry”).
                      <span className="fw-bold">
                        Limit one (1) Entry per receipt, regardless of the
                        number of Qualified Purchases contained on the receipt.
                      </span>
                      Entrants must keep a copy of their receipt. Sponsor
                      reserves the right to require a participant to provide
                      original receipt for verification purposes upon request.
                    </p>
                    <p>
                      <span className="fw-bold">b. Instagram:</span> To enter
                      the Sweepstakes on Instagram, during an Entry Period, an
                      Entrant can create a public post from their public
                      Instagram account with the required hashtags of
                      #ChewItBeforeYouDoIt and #Sweeps to receive one (1) entry
                      into the random drawing corresponding to the Entry Period
                      in which the post is made. The hashtags
                      #ChewItBeforeYouDoIt and #Sweeps must be included in the
                      post to enter and to be eligible to win. Incomplete
                      entries will be disqualified. Proof of posting on the
                      social media site is not considered proof of delivery to
                      or receipt by Sponsor of such entry.
                    </p>

                    <p>
                      <span className="fw-bold">c. Twitter:</span> To enter the
                      Sweepstakes on Twitter, during an Entry Period an Entrant
                      can create a public post from their public Twitter account
                      with the required hashtags of #ChewItBeforeYouDoIt and
                      #Sweeps within the caption of the post to receive one (1)
                      entry into the random drawing corresponding to the Entry
                      Period in which the post is made. The hashtags
                      #ChewItBeforeYouDoIt and #Sweeps must be included in the
                      post to enter and to be eligible to win. Incomplete
                      entries will be disqualified. Proof of entering
                      information at the social media site is not considered
                      proof of delivery to or receipt by Sponsor of such entry.
                    </p>

                    <p>
                      <span className="fw-bold">d. TikTok:</span> To enter the
                      Sweepstakes on TikTok, during an Entry Period an Entrant
                      can create a public post from their public TikTok account
                      with the required hashtags of #ChewItBeforeYouDoIt and
                      #Sweeps within the caption of the post to receive one (1)
                      entry into the random drawing corresponding to the Entry
                      Period in which the post is made. The hashtags
                      #ChewItBeforeYouDoIt and #Sweeps must be included in the
                      post to enter and to be eligible to win. Incomplete
                      entries will be disqualified. Proof of entering
                      information on the social media site is not considered
                      proof of delivery to or receipt by Sponsor of such entry.
                    </p>

                    <p>
                      <span className="fw-bold">PHOTO REQUIREMENTS:</span>{" "}
                      Photos may not contain, as determined by the Sponsor or
                      Administrator, in its sole discretion, any content that:
                    </p>
                    <ul>
                      <li>
                        is sexually explicit or suggestive; unnecessarily
                        violent or derogatory of any ethnic, racial, gender,
                        religious, professional or age group; profane or
                        pornographic; contains nudity;
                      </li>
                      <li>
                        promotes alcohol, illegal drugs, tobacco,
                        firearms/weapons (or the use of any of the foregoing);
                        promotes any activities that may appear unsafe or
                        dangerous; promotes any particular political agenda or
                        message;
                      </li>
                      <li>
                        is obscene or offensive; endorses any form of hate or
                        hate group;
                      </li>
                      <li>
                        defames, misrepresents or contains disparaging remarks
                        about other people or companies;
                      </li>
                      <li>
                        contains trademarks, logos, or trade dress (such as
                        distinctive packaging or building exteriors/interiors)
                        owned by others, without permission;
                      </li>
                      <li>
                        contains any personal identification, such as license
                        plate numbers, personal names, e-mail addresses or
                        street addresses;
                      </li>
                      <li>
                        contains copyrighted materials owned by others
                        (including photographs, sculptures, paintings, and other
                        works of art or images published on or in websites,
                        television, movies or other media), without permission;
                      </li>
                      <li>
                        contains materials embodying the names, likenesses,
                        voices, or other indicia identifying any person,
                        including, without limitation, celebrities and/or other
                        public or private figures, living or dead, without
                        permission;
                      </li>
                      <li>
                        contains look-alikes of celebrities or other public or
                        private figures, living or dead;
                      </li>
                      <li>
                        communicates messages or images inconsistent with the
                        positive images and/or good will to which we wish to
                        associate; and/or
                      </li>
                      <li>violates any law.</li>
                    </ul>

                    <p>
                      By submitting a photo on social media in connection with
                      this Sweepstakes, entrant hereby grants permission for the
                      photo to be posted on{" "}
                      <a
                        href="https://www.extragum.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://www.extragum.com/
                        <span className="visually-hidden">
                          opens in a new window
                        </span>
                      </a>{" "}
                      or other Mars websites. Entrant agrees that Released
                      Parties (as defined below) are not responsible for any
                      unauthorized use of photos by third parties. Released
                      Parties do not guarantee the posting of any photo and may
                      remove a posted photo at any time.
                    </p>

                    <p>
                      Social media posts must not be obscene or offensive; must
                      not depict alcohol, illegal drugs, tobacco,
                      firearms/weapons, or any activities that may appear unsafe
                      or dangerous or illegal; and must not be sexually explicit
                      or suggestive, all as determined by Sponsor in its sole
                      discretion. Social media must be suitable for presentation
                      in a public forum, at the sole discretion of Sponsor.
                    </p>

                    <p>
                      <span className="fw-bold">
                        Limit one (1) entry per person per e-mail address or
                        social media account method per day and a total of three
                        (3) entries per day, regardless of method of entry. For
                        example, an entrant may make a qualified purchase and
                        enter on two different social media platforms per day or
                        enter on three different social media platforms without
                        a purchase per day.
                      </span>
                      A “day” is defined as 12:00:00 a.m. ET through 11:59:59
                      p.m. ET. Entrants may not enter with multiple social media
                      handles on a single social media platform, nor may
                      entrants use any other device or artifice to enter
                      multiple times. Any person who attempts to enter with
                      multiple identities or uses any device or artifice to
                      enter multiple times will be disqualified and forfeits any
                      and all prizes won, at Sponsor's discretion. In the event
                      of a dispute about entries submitted by multiple
                      individuals using the same email account, address or
                      social media method, the entry will be deemed to have been
                      submitted by the authorized account holder of the email
                      account used to enter the Sweepstakes or the social media
                      account used at the actual time of entry, as long as such
                      person is otherwise eligible to participate in accordance
                      with these Official Rules. The authorized account holder
                      is defined as the natural person who is assigned an email
                      address by an Internet access provider, online service
                      provider or other organization that is responsible for
                      assigning email addresses or the domain associated with
                      the submitted email address. Multiple entries per
                      person/email address/social media account/wireless
                      telephone number in excess of the daily entry limit, or
                      any use or suspected use of artifice, bots, robotic,
                      repetitive, automatic, programmed, or similar methods or
                      agents (including, but not limited to sweepstakes entry
                      services/clubs) are prohibited; any Entrant
                      using/benefitting from such methods will be disqualified
                      and their entries voided. Proof of entry submission is not
                      proof of receipt or eligibility for this Sweepstakes.
                    </p>

                    <p>
                      <span className="fw-bold">
                        Important: If the Entrant is participating in the
                        Promotion via a mobile device, message and data rates
                        may apply.
                      </span>
                      The Entrant should consult his/her wireless service
                      provider regarding its pricing plans. Participation may
                      not be available on all mobile devices, as not all mobile
                      devices or cellular telephone providers have the
                      capabilities or carry the service necessary to participate
                      in this method of entry.
                    </p>

                    <p>
                      <span className="fw-bold">
                        4. ENTRY PERIODS AND SELECTION OF WINNER/ODDS:
                      </span>
                      On or about each Drawing Date set forth in the table
                      below, the Administrator will conduct a random drawing for
                      the number of Grand Prize winners indicted in the table
                      below from all Entries received across all Entry Methods
                      combined from each Entry Period Date set forth below. Each
                      Entry Period begins at 12:00:00 a.m. ET and ends at
                      11:59:59 p.m. ET on dates set forth below. The clock on
                      Administrator's server shall be the official time keeping
                      device for this Sweepstakes. Each Sweepstakes entry is
                      eligible only for the particular Entry Period in which the
                      entry is received by the Sponsor. No individual who wins a
                      Grand Prize will be eligible to win another Grand Prize in
                      any subsequent Entry Period. Non-winning entries will not
                      rollover into any subsequent Entry Period nor any other
                      Grand Prize Sweepstakes drawings. Sponsor’s and
                      Administrator’s decision about eligibility for a drawing
                      is final. Odds of winning the Grand Prize depend on the
                      number of eligible entries received during the
                      corresponding Entry Period.
                    </p>

                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Entry Period</th>
                            <th>Entry Period Start/End Date</th>
                            <th>Draw Date</th>
                            <th>Number of Winners</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>2/28/2022 - 3/15/2022</td>
                            <td>3/18/2022</td>
                            <td>48</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>3/16/2022 - 3/31/2022</td>
                            <td>4/6/2022</td>
                            <td>48</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>4/1/2022 - 4/15/2022</td>
                            <td>4/20/2022</td>
                            <td>45</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>4/16/2022 - 4/30/2022</td>
                            <td>5/4/2022</td>
                            <td>45</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <p>
                      <span className="fw-bold">
                        5. PRIZES AND APPROXIMATE RETAIL VALUE (“ARV”):
                      </span>
                      <span className="d-block">
                        <span className="fw-bold">Grand Prize (186):</span> $500
                        awarded to each Grand Prize winner. $500 will be awarded
                        via Venmo or PayPal. Winner must have a Venmo or PayPal
                        account to accept the award. Approximate Retail Value
                        “ARV” of each Grand Prize: $500. ARV of all prizes
                        combined: $93,000.
                      </span>
                    </p>
                    <p>
                      <span className="fw-bold">Prize Restrictions:</span> No
                      transfer, assignment, or substitution of prize (in whole
                      or in part) is allowed, except that Sponsor reserves the
                      right to substitute a similar prize of equal or greater
                      value. Prizes must be claimed as set forth in Rule 6
                      below. All federal, state, and local taxes, if any, are
                      the sole responsibility of the winner. In no event will
                      more than the stated number of prizes be awarded in this
                      Sweepstakes.
                      <span className="fst-italic">
                        Any prize pictured or mentioned in advertising or
                        Sweepstakes materials is for illustrative purposes only
                        and may not be the actual prize awarded.
                      </span>
                      Other restrictions may apply. ALL FEDERAL, STATE AND LOCAL
                      TAXES, AND ANY OTHER COSTS AND EXPENSES, ASSOCIATED WITH
                      THE RECEIPT OR USE OF ANY PRIZE ARE THE SOLE
                      RESPONSIBILITY OF THE WINNER.
                    </p>

                    <p>
                      <span className="fw-bold">6. HOW TO CLAIM A PRIZE:</span>
                      Potential Grand Prize winners will be notified by email or
                      via a comment or direct message through the social media
                      platform that they used to enter the Sweepstakes as solely
                      determined by Sweepstakes Entities. You are not a winner
                      until your prize claim has been timely received and
                      verified by the Sweepstakes Entities, your entry and
                      eligibility have been verified, and you have complied with
                      these Official Rules. Potential winner may be required to
                      furnish proof of identification and other proof of
                      eligibility under these Official Rules. In the event a
                      potential winner is a minor, proof of parental approval
                      will be required. Grand Prize elements will be delivered
                      to Grand Prize winner by Administrator.
                    </p>

                    <p>
                      <span className="fw-bold">
                        7. LIMITATION OF LIABILITY:
                      </span>{" "}
                      The Sweepstakes Entities are not responsible for
                      illegible, lost, late, incomplete, stolen, misdirected
                      (including into spam/junk folders), postage due, returned,
                      or undeliverable entries, texts, email or postal mail, or
                      any other Sweepstakes related communications; or for any
                      computer, telephone, satellite, cable, network, electronic
                      or Internet hardware or software malfunctions, failures,
                      connections, or availability; or garbled, corrupt, or
                      jumbled transmissions, service provider/Internet/website
                      use, net accessibility, incompatibility, availability or
                      traffic congestion; or any technical, mechanical,
                      printing, typographical or other error, unauthorized human
                      intervention, or the incorrect or inaccurate capture of
                      registration information, or the failure to capture, or
                      loss of, any such information. The Sweepstakes Entities
                      are not responsible for any incorrect or inaccurate
                      information, whether caused by any Website users,
                      tampering, hacking, or by any of the equipment or
                      programming associated with or used in the Sweepstakes and
                      assume no responsibility for any error, omission,
                      interruption, deletion, defect, delay in operation or
                      transmission, communications line failure, technical
                      error, theft, destruction, or unauthorized access to any
                      website(s). Sweepstakes Entities are not responsible for
                      any injury, including death, disability, or other damage,
                      whether personal or property, to Entrants or to any
                      person’s computer or mobile device related to or resulting
                      from participating in the Sweepstakes and/or accepting a
                      Prize. If, for any reason, the Sweepstakes is not capable
                      of running as planned, Sponsor reserves the right, in its
                      sole discretion, to cancel, terminate, modify, or suspend
                      the Sweepstakes, including the selection of prize winners
                      in a manner it deems fair and reasonable from among
                      eligible entries received prior to such cancellation,
                      termination, modification or suspension. If because of
                      technical, typographical, mechanical, or other errors or
                      for any other reason, there are more claims for a prize
                      than prizes offered as stated in these Official Rules, a
                      random drawing will be held among all eligible claimants
                      in such category to award such prize. All properly claimed
                      prizes will be awarded, but in no event will Sponsor award
                      more prizes than are provided for in the Official Rules.
                      This Sweepstakes is in no way sponsored, endorsed,
                      administered by, or associated with Instagram, Twitter, or
                      TikTok.
                    </p>

                    <p>
                      <span className="fw-bold">
                        IN NO EVENT WILL THE SWEEPSTAKES ENTITIES BE RESPONSIBLE
                        OR LIABLE FOR ANY DAMAGES OR LOSSES OF ANY KIND,
                        INCLUDING WITHOUT LIMITATION INDIRECT, INCIDENTAL,
                        CONSEQUENTIAL OR PUNITIVE DAMAGES ARISING OUT OF THIS
                        SWEEPSTAKES, PARTICIPATION IN SWEEPSTAKES AND/OR PRIZE
                        RELATED ACTIVITIES, THE USE OR MISUSE OF A PRIZE, ACCESS
                        TO AND USE OF ANY PARTICIPATING WEBSITES OR THE
                        DOWNLOADING FORM AND/OR PRINTING MATERIAL DOWNLOADED
                        FROM ANY WEBSITES ASSOCIATED WITH THIS SWEEPSTAKES.
                        WITHOUT LIMITING THE FOREGOING, EVERYTHING ON MARS'S
                        WEBSITES ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY
                        KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT
                        LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY,
                        FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                        SOME JURISDICTIONS MAY NOT ALLOW LIMITATIONS OR
                        EXCLUSION OF LIABILITY FOR CERTAIN DAMAGES OR EXCLUSION
                        OF IMPLIED WARRANTIES, SO SOME OF THE ABOVE LIMITATIONS
                        MAY NOT APPLY TO YOU.
                      </span>
                    </p>

                    <p>
                      <span className="fw-bold">8. GENERAL:</span> Sponsor
                      reserves the right to disqualify any individual in its
                      sole and absolute discretion, including but not limited to
                      any individual suspected of, or found, in its sole
                      opinion, to be tampering with the operation of the
                      Sweepstakes; to be acting in violation of these Official
                      Rules; or to be acting in an unsportsmanlike manner or
                      with the intent to disrupt the normal operation of a
                      Sweepstakes. ANY ATTEMPT TO DELIBERATELY DAMAGE ANY WEB
                      SITE OR UNDERMINE THE LEGITIMATE OPERATION OF THIS
                      SWEEPSTAKES IS A VIOLATION OF CRIMINAL AND CIVIL LAWS.
                      SHOULD SUCH AN ATTEMPT BE MADE, THE SPONSOR RESERVES THE
                      RIGHT TO SEEK DAMAGES OR OTHER REMEDIES FROM ANY SUCH
                      PERSON(S) RESPONSIBLE FOR THE ATTEMPT TO THE FULLEST
                      EXTENT PERMITTED BY LAW. All entries and/or materials
                      submitted become the property of Sponsor and will not be
                      returned. In the event of any conflict with any
                      Sweepstakes details contained in these Official Rules and
                      Sweepstakes details contained in promotional materials
                      (including but not limited to point of sale, television,
                      and print advertising, promotional packaging, and other
                      promotion media, and any communications made by any person
                      in any medium), the details of the Sweepstakes as set
                      forth in these Official Rules shall prevail. If any
                      provision of these Official Rules or any word, phrase,
                      clause, sentence, or other portion thereof should be held
                      unenforceable or invalid for any reason, then that
                      provision or portion thereof shall be modified or deleted
                      in such manner as to render the remaining provisions of
                      these Official Rules valid and enforceable.
                    </p>

                    <p>
                      <span className="fw-bold">
                        9. RELEASE, INDEMNITY & GENERAL CONDITIONS OF
                        PARTICIPATION:
                      </span>
                      By entering the Sweepstakes, each Entrant agrees, to the
                      fullest extent permitted by law to be bound by these
                      Official Rules and by all applicable laws and decisions of
                      Sponsor and Administrator which shall be binding and
                      final, and to waive any right to claim ambiguity with
                      respect to these Official Rules. To the maximum extent
                      permitted by law, Entrants and winner(s) agree to waive,
                      release, indemnify, defend and hold harmless (“release”)
                      Sponsor and all other Sweepstakes Entities, their parents,
                      affiliates, subsidiaries and divisions, and their
                      respective directors, officers, employees and agents, and
                      any social media platforms referenced in these Official
                      Rules (“Released Parties”) from and against any and all
                      threatened or actual actions, liabilities, claims,
                      demands, losses, lawsuits, judgments, settlements, fines,
                      damages, costs and expenses (including reasonable attorney
                      fees) whether or not litigation is commenced (“disputes”)
                      arising at any time from the Sweepstakes and any element
                      thereof; the entry (in whole or in part); participation
                      (including travel) in any Sweepstakes, event, and/or
                      prize-related activity, or inability to receive or
                      participate in, or parts thereof; the delivery,
                      acceptance, use, mis-use of a prize or any failure with
                      respect thereto by any person or entity; personal injuries
                      including death, damage to or destruction of property,
                      violation of any rights of publicity or privacy,
                      defamation or portrayal in a false light (whether
                      intentional or unintentional), whether under a theory of
                      contract, tort (including negligence), warranty or other
                      theory arising from or relating directly or indirectly to
                      the Sweepstakes and any element thereof; any act, default,
                      omission, non-compliance, and/or a violation or breach of
                      any law or agreement, representation, warranty or covenant
                      made herein, or any other agreements by/with Entrant, the
                      Sponsor and/or any Sweepstakes Entity or Released Party,
                      or any other third-person or entity who may not be a party
                      to these Rules.
                    </p>

                    <p>
                      To the maximum extent permitted by law, Entrants and
                      winners covenant not to sue any Released Party or cause
                      them to be sued regarding any matter released above; and
                      further covenant not to disaffirm, limit or rescind any
                      other releases in these Rules to the fullest extent
                      permitted by law. A waiver by one or more of the
                      Sweepstakes Entities of any term in these Rules does not
                      constitute a waiver of any other provision. If any item or
                      provision contained in these Rules or any part thereof is
                      declared or becomes unenforceable, invalid, or illegal for
                      any reason, all other terms and provisions of these Rules
                      shall remain in full force and effect as if executed
                      without the offending provision appearing therein.
                    </p>

                    <p>
                      Sweepstakes Entities shall not be liable to a winner or
                      any other person or entity for failure to execute the
                      Sweepstakes, or any part thereof, or supply a prize in
                      whole or in part, by reason of any act of God, any
                      action(s), regulation(s) order(s) or request(s) by any
                      governmental or quasi-governmental entity (whether or not
                      the action(s), regulation(s), order(s) or request(s)
                      prove(s) to be invalid), equipment failure, terrorist
                      acts, earthquake, war, fire, flood, explosion, unusually
                      severe weather, disease epidemic, embargo, labor dispute
                      or strike (whether legal or illegal), labor or material
                      shortage, transportation interruption of any kind, work
                      slow-down, civil disturbance, insurrection, riot, event
                      delay or cancellation, or any similar or dissimilar event
                      beyond their reasonable control.
                    </p>

                    <p>
                      <span className="fw-bold">
                        10. DISPUTES: THIS SWEEPSTAKES IS GOVERNED BY THE LAWS
                        OF THE UNITED STATES AND THE STATE OF ILLINOIS WITHOUT
                        RESPECT TO CONFLICT OF LAW DOCTRINES. AS A CONDITION OF
                        PARTICIPATING IN THIS SWEEPSTAKES, EACH ENTRANT AGREES
                        THAT ANY AND ALL DISPUTES WHICH CANNOT BE RESOLVED
                        BETWEEN THE PARTIES, AND CAUSES OF ACTION ARISING OUT OF
                        OR CONNECTED WITH THIS SWEEPSTAKES, SHALL BE RESOLVED
                        INDIVIDUALLY, WITHOUT RESORT TO ANY FORM OF CLASS
                        ACTION, EXCLUSIVELY BEFORE A COURT LOCATED IN THE COUNTY
                        OF COOK COUNTY, ILLINOIS HAVING JURISDICTION. FURTHER,
                        IN ANY SUCH DISPUTE, UNDER NO CIRCUMSTANCES WILL
                        PARTICIPANT BE PERMITTED TO OBTAIN AWARDS FOR, AND
                        HEREBY WAIVES ALL RIGHTS TO CLAIM PUNITIVE, EXEMPLARY,
                        INCIDENTAL OR CONSEQUENTIAL DAMAGES, INCLUDING
                        ATTORNEYS' FEES OR OTHER RELATED COSTS OF BRINGING A
                        CLAIM, OR TO RESCIND THIS AGREEMENT OR SEEK INJUNCTIVE
                        OR OANY OTHER EQUITABLE RELIEF. ALL CAUSES OF ACTION
                        MUST BE FILED WITHIN ONE (1) YEAR OF THE TIME THE CAUSE
                        OF ACTION ACCRUED OR THE SHORTEST TIME ALLOWED BY
                        APPLICABLE LAW, OR THE CAUSE OF ACTION SHALL BE FOREVER
                        BARRED.
                      </span>
                    </p>

                    <p>
                      <span className="fw-bold">11. PRIVACY POLICY:</span> Any
                      personally identifiable information collected during an
                      Entrant's participation in the Sweepstakes will be
                      collected by Sponsor or its agent, and will be used by
                      Sponsor, its affiliates, agents and marketers for purposes
                      of the proper administration and fulfillment of the
                      Sweepstakes as described in these Official Rules and in
                      accordance with Sponsor's Privacy Policy as stated at{" "}
                      <a
                        href="https://www.mars.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://www.mars.com/privacy
                        <span className="visually-hidden">
                          opens in a new window
                        </span>
                      </a>{" "}
                      and any communications an Entrant may agree during the
                      registration process to receive in the future.
                    </p>

                    <p>
                      <span className="fw-bold">12. PUBLICITY RIGHTS:</span> By
                      participating in the Sweepstakes and/or accepting a Prize,
                      each Entrant and winner grants (and agrees to confirm that
                      grant in writing, or their parent or legal guardian will
                      confirm that grant in writing if winner is a minor in
                      their state of residence) permission for Sponsor and/or
                      Sponsor's designee the perpetual right to use their name,
                      biographical information, photos and/or likeness, and
                      statements for promotion, trade, commercial, advertising
                      and publicity purposes, at any time or times, in all media
                      now known or hereafter discovered including live
                      television, worldwide, including but not limited to on the
                      World Wide Web and Internet including Sponsor’s and/or
                      unrelated third-parties’ social media platforms and sites,
                      without notice, review or approval and without additional
                      compensation except where prohibited by law.
                    </p>

                    <p>
                      <span className="fw-bold">13. WINNERS’ LIST:</span> For
                      the name of the winner, send an email to{" "}
                      <a
                        href="mailto:winnerslist@pfcfulfills.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        winnerslist@pfcfulfills.com
                        <span className="visually-hidden">
                          opens in a new window
                        </span>
                      </a>{" "}
                      with “44568 WINNERS LIST” in the subject line. Limit one
                      request per person/email address. Requests must be
                      received by 11:59pm CT on May 31, 2022.  List will be sent
                      once all winners have been verified. Limit one request per
                      person/email address.
                    </p>

                    <p>
                      14. Mars Wrigley Confectionery US, LLC, 800 High St,
                      Hackettstown, NJ 07840
                    </p>

                    <p>© 2022 Mars or Affiliates</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Rules;
